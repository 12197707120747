import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent {
  @Input() label!: string;
  @Input() labelFor!: string;
  @Input() isRequired: Boolean = false;
  @Input() characterCounterLength!: number;
  @Input() characterCounterMaxLength!: number;
  @Input() info!: string;
  @Input() referenceInfo!: string;
  @Input() referenceUrl!: string;
  @Input() copy: boolean = false;
  @Input() boldLabel: boolean = false;
  @Output() copyEvent: EventEmitter<void> = new EventEmitter();

  openReference() {
    window.open(this.referenceUrl, '_blank');
  }
}
