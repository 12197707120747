<ng-container
  *ngIf="{ created: workplanWasCreated$ | async } as workplanWasCreated"
>
  <ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState" class="adap-form-container">
      <app-input-container
        label="project name"
        labelFor="name"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.name"
      >
        <input
          id="name"
          name="name"
          type="text"
          placeholder="enter project name"
          maxlength="100"
          class="adap-form-input"
          [ngrxFormControlState]="formState.controls.name!"
        />
      </app-input-container>

      <app-input-container
        label="phase"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.phaseId"
        *ngIf="availablePhases$ | async as availablePhases"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            [ngrxFormControlState]="formState.controls.phaseId!"
            placeholder="project phase"
          >
            <ng-container *ngFor="let phase of availablePhases">
              <mat-option value="{{ phase }}">
                {{ phase }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="project type"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.onShore"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            multiple
            placeholder="project type"
            [(ngModel)]="selectedProjectTypes"
            (ngModelChange)="onProjectTypeChange($event)"
            [ngModelOptions]="{ standalone: true }"
          >
            <mat-option value="onShore">onshore</mat-option>
            <mat-option value="offShore">offshore</mat-option>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="planned start date"
        labelFor="plannedStartDate"
        [app-input-validation-container]="formState.controls.plannedStartDate"
      >
        <mat-form-field class="adap-datepicker" appearance="outline">
          <input
            id="plannedStartDate"
            matInput
            placeholder="mm/dd/yyyy"
            [ngrxFormControlState]="formState.controls.plannedStartDate!"
            [ngrxValueConverter]="dateOnlyToISOString"
            [matDatepicker]="plannedStartDatepicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="plannedStartDatepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #plannedStartDatepicker> </mat-datepicker>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="planned completion date"
        labelFor="plannedCompletionDate"
        [app-input-validation-container]="
          formState.controls.plannedCompletionDate
        "
      >
        <mat-form-field class="adap-datepicker" appearance="outline">
          <input
            id="plannedCompletionDate"
            matInput
            placeholder="mm/dd/yyyy"
            [ngrxFormControlState]="formState.controls.plannedCompletionDate!"
            [ngrxValueConverter]="dateOnlyToISOString"
            [matDatepicker]="plannedCompletionDatepicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="plannedCompletionDatepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #plannedCompletionDatepicker> </mat-datepicker>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="operated"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.nojv"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            [ngrxFormControlState]="formState.controls.nojv!"
            placeholder="operated"
          >
            <mat-option [value]="false">Chevron</mat-option>
            <mat-option [value]="true">NOJV</mat-option>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="themes"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.themes"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            multiple
            [ngrxFormControlState]="formState.controls.themes!"
            placeholder="themes"
          >
            <ng-container *ngFor="let theme of projectThemes$ | async">
              <mat-option [value]="theme">{{ theme }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="business unit"
        [app-input-validation-container]="formState.controls.businessUnitId"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            [ngrxFormControlState]="formState.controls.businessUnitId!"
            placeholder="business unit"
          >
            <ng-container *ngFor="let businessUnit of businessUnits$ | async">
              <mat-option
                [value]="businessUnit.id"
                matTooltip="{{ businessUnit.name }} ({{ businessUnit.code }})"
                matTooltipPosition="above"
                matTooltipShowDelay="1000"
              >
                {{ businessUnit.name }} ({{ businessUnit.code }})
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="lead by"
        [info]="leadByTooltip"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.leadBy"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            [ngrxFormControlState]="formState.controls.leadBy!"
            placeholder="lead by"
          >
            <ng-container *ngFor="let leadBy of leadByList$ | async">
              <mat-option [value]="leadBy"> {{ leadBy }} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        class="user-anonymity"
        label="user anonymity"
        [isRequired]="true"
        [info]="userAnonymityTooltip"
        [app-input-validation-container]="formState.controls.userAnonymity"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            placeholder="user anonymity"
            [ngrxFormControlState]="formState.controls.userAnonymity!"
          >
            <mat-option [value]="true">hide names</mat-option>
            <mat-option [value]="false">show names</mat-option>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="billing rate USD"
        labelFor="billingRateUSD"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.billingRateUSD"
      >
        <input
          placeholder="billing rate USD"
          id="billingRateUSD"
          name="billingRateUSD"
          class="adap-form-input"
          type="number"
          min="0"
          [ngrxFormControlState]="formState.controls.billingRateUSD!"
        />
      </app-input-container>

      <app-input-container
        label="DA time"
        [isRequired]="true"
        [app-input-validation-container]="formState.controls.daTime"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            [ngrxFormControlState]="formState.controls.daTime!"
            placeholder="DA time"
          >
            <ng-container *ngFor="let daTime of totalCostDATime">
              <mat-option [value]="daTime"> {{ daTime }}% </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="SME hours"
        [app-input-validation-container]="formState.controls.smeMargin"
      >
        <mat-form-field class="adap-select" appearance="outline">
          <mat-select
            [ngrxFormControlState]="formState.controls.smeMargin!"
            placeholder="SME hours"
          >
            <ng-container *ngFor="let smeHours of smeHoursPercent">
              <mat-option [value]="smeHours"> {{ smeHours }}% </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </app-input-container>

      <app-input-container
        label="ipims domain"
        [app-input-validation-container]="formState.controls.ipimsDomain"
      >
        <app-ipims-domain-selector-input
          [ngrxFormControlState]="formState.controls.ipimsDomain"
          placeholder="ipims domain"
        ></app-ipims-domain-selector-input>
      </app-input-container>
      <mat-expansion-panel [expanded]="true" class="default-expansion-panel">
        <mat-expansion-panel-header class="default-expansion-header">
          <mat-panel-title class="default-expansion-title">
            project description
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-input-container
          label="short description"
          labelFor="description"
          [isRequired]="true"
          [characterCounterLength]="descriptionLength ?? 0"
          [characterCounterMaxLength]="descriptionMaxLength"
          [app-input-validation-container]="formState.controls.description"
          class="description"
        >
          <rich-text-editor
            [formControlState]="formState.controls.description!"
            id="description"
            name="description"
            [counter]="descriptionLength"
            (counterChange)="updateDescriptionCounter($event)"
          ></rich-text-editor>
        </app-input-container>
      </mat-expansion-panel>
    </form>
  </ng-container>
</ng-container>
