import { Component, Input } from '@angular/core';
import Quill from 'quill';

const FontAttributor = Quill.import('attributors/class/font')as any;
FontAttributor.whitelist = [
  'GothamNarrowBook',
  'sansserif',
  'roboto',
  'cursive',
  'fantasy',
  'monospace'
];
const SizeStyle = Quill.import('attributors/style/size') as any;
SizeStyle.whitelist = [ '0.75em', '1em', '1.5em', '2.5em' ];
Quill.register(SizeStyle, true);
Quill.register(FontAttributor, true);

@Component({
  selector: 'rich-text-viewer',
  templateUrl: './rich-text-viewer.component.html',
  host: {
    class: 'rich-text-viewer',
  },
})
export class RichTextViewerComponent{
  @Input() content: string = "";
  @Input() format: "object" | "html" | "text" | "json" = "html";
}