<div class="quill-editor-container" [class.editor-invalid]="formControlState.isTouched && formControlState.isInvalid" [class.editor-disabled]="formControlState.isDisabled">
  <quill-editor
    [ngrxFormControlState]="formControlState" 
    class="quill-editor" 
    [modules]="editorModules" 
    (onEditorCreated)="onEditorCreated($event)"
    [attr.id]="id"
    [attr.name]="name"
    bounds=".quill-editor-container"
    >
  </quill-editor>
</div>