<ng-container *ngIf="formState$ | async as formState">
  <form novalidate [ngrxFormState]="formState" class="adap-form-container">
    <app-input-container
      label="DA lead"
      for="dalead"
      [isRequired]="true"
      [app-input-validation-container]="formState.controls.daLead"
    >
      <app-user-search
        placeholder="DA lead"
        id="dalead"
        [ngrxFormControlState]="formState.controls.daLead!"
      ></app-user-search>
    </app-input-container>

    <app-input-container label="DA manager" for="damanager">
      <app-user-search
        placeholder="DA manager"
        id="damanager"
        [ngrxFormControlState]="formState.controls.daManager!"
      ></app-user-search>
    </app-input-container>

    <app-multi-users-search
      label="DA support"
      [ngrxFormControlState]="formState.controls.daSupports!"
    ></app-multi-users-search>

    <app-multi-users-search
      label="project contact"
      [ngrxFormControlState]="formState.controls.projectContacts!"
    ></app-multi-users-search>
  </form>
</ng-container>
