<div class="header">
  <div class="menu-toggle-wrapper">
    <button class="adap-btn-blank menu-toggle" [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="cvx-hamburger"></mat-icon>
      menu
    </button>
  </div>
  <a class="sitename" [routerLink]="['/']">
    <img alt="chevron logo" class="cvx-img" src="/assets/images/CvxLogo.svg" />
    adap
  </a>
  <div class="right-header">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="btn-settings"
      matTooltip="settings"
      matTooltipPosition="above"
      matTooltipShowDelay="500"
    >
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu>
      <a [routerLink]="[GLOBAL_SETTINGS_ROUTE]" class="menu-name" mat-menu-item>
        global settings
      </a>
      <a
        [routerLink]="[GLOBAL_METRICS_ROUTE]"
        class="menu-name"
        mat-menu-item
        *ngIf="isUserDAEngineer"
      >
        global metrics
      </a>
    </mat-menu>
    <app-user-button></app-user-button>
  </div>
</div>

<nav class="navigation-bar">
  <ul class="tabs">
    <li class="tab">
      <a
        *ngIf="isUserDAEngineer"
        class="tab-link"
        [routerLink]="['/da-engineer']"
        routerLinkActive="active"
      >
        design assurance
      </a>
      <a
        *ngIf="isUserChapterManager"
        class="tab-link"
        [routerLink]="['/chapter-manager']"
        routerLinkActive="active"
      >
        chapter manager
      </a>
      <a
        *ngIf="isUserSME"
        class="tab-link"
        [routerLink]="['/sme']"
        routerLinkActive="active"
      >
        SME
      </a>
      <a
        *ngIf="isUserProjectContact"
        class="tab-link"
        [routerLink]="['/project-contact']"
        routerLinkActive="active"
      >
        project contact
      </a>
    </li>
  </ul>
</nav>

<mat-menu #menu="matMenu" class="global-menu-wrapper">
  <app-global-menu></app-global-menu>
</mat-menu>
