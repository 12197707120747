<ng-container
  *ngFor="
    let selectedUserId of selectedUsersIds;
    index as i;
    trackBy: trackUserId
  "
>
  <app-input-container
    *ngIf="!readonly || selectedUserId"
    [label]="label + ' ' + (i + 1)"
  >
    <app-user-search
      [placeholder]="label + ' ' + (i + 1)"
      [userId]="selectedUserId"
      (selectionChanged)="onSelectionChanged($event, i)"
      [readonly]="readonly"
    ></app-user-search>
  </app-input-container>
</ng-container>
