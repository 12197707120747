import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-panel',
  templateUrl: './title-panel.component.html',
  styleUrls: ['./title-panel.component.scss'],
})
export class TitlePanelComponent {
  selectedUsersIds: (string | null)[] = [];

  @Input() title!: string;
}